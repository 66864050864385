@import "node_modules/@fv-components/theme/src/_index.scss";

.searchOrgContacts {
  overflow-y: auto;
  height: 95%;
}

.orgContact {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  width: 100%;
  text-align: left;

  &:hover {
    cursor: pointer;
  }
}

.contactsHeader {
  position: sticky;
  top: 0;
  background-color: white;
  padding-bottom: $fv-spacing-small;
}

.contactsSeparator {
  margin: $fv-spacing-smaller $fv-spacing-small;
  border: none;
  border-top: $fv-border-default;
}

.contactsNoResults,
.contactsLoading {
  margin: $fv-spacing-default;
}

.projectEmail {
  font-size: $fv-font-size-smaller;
  margin: 0 $fv-spacing-small $fv-spacing-default;
}

.contactsCreateWrapper {
  flex: 1;
}

.contactsCreateWrapper > button {
  height: 25px;
}
