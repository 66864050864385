@import "node_modules/@fv-components/theme/src/_index.scss";

.docActivityHeader {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}

.docActivityHeaderInputFocus {
  @extend .docActivityHeader;
  z-index: 0;
}

.docActivityRefreshButton {
  padding-left: $fv-spacing-small;
  padding-top: $fv-spacing-small;
}

.docActivityFilename {
  justify-content:space-between;
  font-weight: $fv-font-weight-bolder;
  padding: $fv-spacing-small $fv-spacing-default $fv-spacing-default;
  overflow: hidden;
  text-overflow: ellipsis;
}

.docActivityTask {
  background-color: $fv-color-grey-lightest;
  padding: $fv-spacing-small $fv-spacing-default;
  font-size: $fv-font-size-small;
}

.docActivityTaskDetail {
  margin-bottom: $fv-spacing-small;
}

.docActivityTaskLabel {
  color: $fv-color-grey-dark;
  font-weight: $fv-font-weight-bolder;
}

.docActivityTaskLabelDueToday {
  @extend .docActivityTaskLabel;
  color: $fv-color-red-dark;
}

.docActivityTaskLabelOverdue {
  @extend .docActivityTaskLabel;
  color: $fv-color-red-bright;
}

.docActivityTaskDivider {
  height: $fv-spacing-smaller;
  background-color: white;
}

@media (max-height: 760px) {
  .docActivityFilename {
    white-space: nowrap;
  }
}
