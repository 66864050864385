@import "node_modules/@fv-components/theme/src/_index.scss";

.persona {
  margin: auto;
  width: 100px;
}

.fullname {
  text-align: center;
  font-size: large;
  font-weight: $fv-font-weight-bold;
  padding: $fv-font-size-large;
}

.role {
  border: $fv-border-default;
  margin: auto;
  width: 33%;
  text-align: center;
  border-radius: $fv-border-radius-default;
  padding: $fv-spacing-small;
}

.userIcon {
  padding: $fv-spacing-small;
}

.contactInfoSection {
  color: $fv-color-grey-light;
  padding-top: $fv-spacing-large;
  padding-bottom: $fv-spacing-large;
}

.contactInfoContainer {
  height: 300px;
  overflow-y: auto;
}

.emailSection {
  padding-bottom: $fv-spacing-large;
}

.emailRow {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 2fr);
  padding-bottom: $fv-spacing-large;
}

.emailLabel {
  font-weight: $fv-font-weight-bold;
}

.emailAddress {
  font-weight: $fv-color-grey-dark;
  font-size: $fv-font-size-small;
}

.phoneRow {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 2fr);
  padding-bottom: $fv-spacing-large;
}

.phoneNumber {
  font-weight: $fv-color-grey-dark;
  font-size: $fv-font-size-small;
}

.emailActionButtons {
  width: 130px;
}