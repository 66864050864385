@import "node_modules/@fv-components/theme/src/_index.scss";

.sectionHead {
  cursor: pointer;
  background-color: $fv-color-grey-lightest;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $fv-spacing-small;
  border-top: $fv-border-grey-lighter;
  border-bottom: $fv-border-grey-lighter;

  &.sectionHeadExpanded {
    color: $fv-color-blue-default;
  }
}

.sectionHeadLeft {
  display: flex;
  align-items: center;
  font-weight: $fv-font-weight-bold;
}

.sectionHeadIcon {
  font-size: 20px;
  padding-right: $fv-spacing-default;
}

.sectionExpandIcon {
  font-weight: $fv-font-weight-bolder;
}

.sectionBody {
  overflow-y: auto;
  background-color: $fv-color-blue-lighter;
}

.sectionBody.hide {
  max-height: 0;
  transition: max-height .5s;
}

.sectionBody.show {
  height: initial;
  max-height: 1000px;
  transition: max-height .7s ease-in;
}
