@import "node_modules/@fv-components/theme/src/_index.scss";

.container {
  position: fixed;
  bottom: $fv-spacing-large;
  display: none;
  flex-direction: column;
  gap: $fv-spacing-large;
  align-items: center;
  width: 100%;
  padding: 0px $fv-spacing-large $fv-spacing-large $fv-spacing-large;

  &.show {
    display: flex;
  }
}

.message {
  width: 100%;
  background-color: white;
  box-shadow: 2px 2px 8px -1px $fv-color-grey-darker;
  padding: $fv-spacing-large;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.errorIcon {
  color: $fv-color-red-bright;
}

.closeIcon{
  color: $fv-color-blue-dark;
}

.text {
  display: flex;
  flex-direction: row;
  gap: $fv-spacing-large;
}
