@import "node_modules/@fv-components/theme/src/_index.scss";

.component {
  display: grid;
  grid-template-columns: 25px auto 25px;
  width: 100%;
  background: $fv-color-grey-lightest;
  padding: $fv-spacing-small;
  margin: auto;
  align-items: center;
  padding-bottom: $fv-spacing-small;
  font-size: $fv-font-size-small;
}

.componentEdit {
  @extend .component;
  grid-template-columns: 25px 1fr;
}

.saved {
  color: green;
  font-size: $fv-font-size-small;
  padding: $fv-spacing-small;
}

.checkButton,
.saveButton,
.cancelButton {
  color: $fv-color-blue;
  padding: $fv-spacing-small;
  font-weight: $fv-font-weight-bold;
  background: inherit;
  padding: 0;
  border: 0;
  cursor:pointer;
} 

.cancelButton:hover, .saveButton:hover {
  color: $fv-color-blue-default;
}

.editFilenameView {
  display: grid;
  grid-template-columns: 85% 8% 20%;
}

.filenameView {
  display: grid;
  grid-template-columns: 85% auto;
}

.checkbox {
  color: $fv-color-blue;
}

.filenameDisplay {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.input,
.inputHasErrors {
  border: $fv-border-default;
  border-radius: $fv-border-radius-default;
  padding: $fv-spacing-small;
}


.inputHasErrors {
  border-color: $fv-color-red-bright;
}

.errorMessage {
  display: grid;
  font-size: x-small;
  color: $fv-color-red-bright;
  grid-template-columns: auto;
  grid-column-start: 2;
}
