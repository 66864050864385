@import "node_modules/@fv-components/theme/src/_index.scss";

$xlarge: 100px;
$large: 50px;
$default: 40px;
$small: 30px;

.personaContainer {
  display: flex;
  align-items: center;
}

.personaAvatar {
  height: $default;
  width: $default;
  min-width: $default;
  background-color: $fv-color-blue;
  border-radius: $fv-border-radius-default;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  margin-right: $fv-spacing-default;

  &.rounded {
    border-radius: 50%;
  }

  &.small {
    height: $small;
    width: $small;
    min-width: $small;
  }

  &.large {
    height: $large;
    width: $large;
    min-width: $large;
  }

  &.xlarge {
    height: $xlarge;
    width: $xlarge;
    min-width: $xlarge;
  }

  &.loading {
    background-color: $fv-color-grey-lightest;
    animation: loading 3500ms infinite;

    @keyframes loading {
      0%   {background-color: $fv-color-grey-lightest;}
      50%  {background-color: $fv-color-grey-light;}
      100% {background-color: $fv-color-grey-lightest;}
    }
  }
}

.personaInitials {
  font-size: $default/2;
  line-height: $default;
  color: #fff;
  position: relative;
  font-weight: $fv-font-weight-bolder;

  &.small {
    font-size: $small/2;
    line-height: $small;
  }

  &.large {
    font-size: $large/2;
    line-height: $large;
  }

  &.xlarge {
    font-size: $xlarge/2;
    line-height: $xlarge;
  }
}

.personaDetails {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.personaName {
  font-size: $fv-font-size-small;
  font-weight: $fv-font-weight-bold;
  margin-right: $fv-spacing-small;
}

.personaNameLoading {
  width: 100%;
  padding: $fv-spacing-large 0 0 $fv-spacing-small;
  color: transparent;
  text-shadow: 0 0 4px rgba(0,0,0,0.5);
}

.personaSecondaryText {
  font-size: $fv-font-size-smaller;
  font-style: italic;
}
