@import "node_modules/@fv-components/theme/src/_index.scss";

.emailActivityHeader {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}

.emailActivityHeaderInputFocus {
  @extend .emailActivityHeader;
  z-index: 0;
}

.emailActivityRefreshButton {
  padding-left: $fv-spacing-small;
  padding-top: $fv-spacing-small;
}

.emailActivitySubject {
  justify-content:space-between;
  font-weight: $fv-font-weight-bolder;
  padding: $fv-spacing-small $fv-spacing-default $fv-spacing-default;
  overflow: hidden;
  text-overflow: ellipsis;
}

.emailActivityTask {
  background-color: $fv-color-grey-lightest;
  padding: $fv-spacing-small $fv-spacing-default;
  font-size: $fv-font-size-small;
}

.emailActivityTaskDetail {
  margin-bottom: $fv-spacing-small;
}

.emailActivityTaskLabel {
  color: $fv-color-grey-dark;
  font-weight: $fv-font-weight-bolder;
}

.emailActivityTaskLabelDueToday {
  @extend .emailActivityTaskLabel;
  color: $fv-color-red-dark;
}

.emailActivityTaskLabelOverdue {
  @extend .emailActivityTaskLabel;
  color: $fv-color-red-bright;
}

.emailActivityTaskDivider {
  height: $fv-spacing-smaller;
  background-color: white;
}

@media (max-height: 760px) {
  .emailActivitySubject {
    white-space: nowrap;
  }
}