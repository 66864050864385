@import "node_modules/@fv-components/theme/src/_index.scss";

.emailLink {
  color: $fv-color-blue-default;
  &:hover {
    cursor: pointer;
    color: $fv-color-grey-dark;
  }
}

.emailLinkLabel {
  color: $fv-color-grey;
  padding-left: $fv-spacing-default;
}
