@import "node_modules/@fv-components/theme/src/_index.scss";

// Global use common styles
.hidden {
  display: none;
}

.brandText {
  margin-bottom: $fv-font-size-large;
  font-size: $fv-font-size-large;
  font-weight: $fv-font-weight-bolder;
}

.highlightYellow {
  background: #FDF5D1;

}

.highlightBlue {
  background: #E5F6FF;
}