@import "node_modules/@fv-components/theme/src/_index.scss";

.emailSendInputTextArea {
  height: 50px;
  width: 100%;
  border: $fv-border-default;
  border-radius: $fv-border-radius-default;
  padding: $fv-spacing-default;
  font-family: inherit;
  margin-top: $fv-spacing-small;
}

.emailSendInputSendBtn {
  width: 100%;
  border: $fv-border-default;
  border-radius: $fv-border-radius-default;
}

.emailSendButtonsRow {
  display:flex;
  justify-content: space-between;
  margin-bottom: $fv-spacing-small;
}

.emailSendInputIncludeAttachments {
  border: $fv-border-default;
  border-radius: $fv-border-radius-default;
  margin-top: $fv-spacing-small;
  width: 100%;
}

.emailSendInputIncludeAttachmentsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
}

.emailSendInputSending {
  width: 99.5%;
  margin: -($fv-spacing-default + 1) auto 0;
  border-radius: 0 0 5px 5px;
}
