@import "node_modules/@fv-components/theme/src/_index.scss";

.emailIncludeAttachmentsBtn {
  border: $fv-border-default;
  border-radius: $fv-border-radius-default;
  margin-top: $fv-spacing-small;
  width: 100%;
}

.emailIncludeAttachmentsErrorText {
  margin: $fv-spacing-small 0 $fv-spacing-default;
}

.emailIncludeAttachmentsContainer {
  max-height: 400px;
  overflow: auto;
  margin-bottom: $fv-spacing-large;
  border: $fv-border-default;
  border-radius: $fv-border-radius-default;
}

// Styles for a "short" viewport
@media (max-height: 760px) {
  .emailIncludeAttachmentsContainer {
    margin-top: $fv-spacing-default;
  }
}