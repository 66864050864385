@import "node_modules/@fv-components/theme/src/_index.scss";

.link {
  padding: $fv-spacing-default;
  display: flex;
  justify-content: space-between;
  border-top: $fv-border-grey-lighter;
}

.linkText {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.pr15 {
  padding-right: 15px;
}
