@import "node_modules/@fv-components/theme/src/_index.scss";

.docActivityDocProject {
  margin-top: $fv-spacing-default;
  margin-left: $fv-spacing-large;
}

.docActivityDocLinks {
  margin-top: $fv-spacing-large;
}

.docActivityContainer {
  margin: 0 (-$fv-spacing-small);
}

.docActivityInputContainer {
  padding: $fv-spacing-small;
  bottom: 0;
  background-color: white;
}

.docActivitySendErrorText {
  margin: $fv-spacing-small 0 $fv-spacing-default;
}

.docActivityDivider {
  border-top: $fv-spacing-smaller $fv-color-blue-default $fv-border-style-default;
}

.noDoc {
  padding: 30px;
  font-weight: $fv-font-weight-bolder;
}
