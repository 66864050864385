@import "node_modules/@fv-components/theme/src/_index.scss";

.documentsContainer {
  overflow:auto;
  font-size: $fv-font-size-small;
  padding-bottom: $fv-spacing-large;
  height:85%;
}

.docContainer {
  display: grid;
  grid-template-columns: 9fr 1fr;
  grid-gap: $fv-border-default;
  width:100%;
  border-bottom: $fv-border-default;
}

.docIcon {
  margin-right: $fv-spacing-smaller;
  margin-left: $fv-spacing-smaller;
  font-size: $fv-font-size-smaller;
}

.docItem {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  padding: $fv-spacing-large $fv-spacing-small $fv-spacing-large $fv-spacing-large;
  &:hover {
    background-color: $fv-color-grey-lightest;
  }
}

.folderIdSelectButton {
  background: $fv-color-blue-default;
}

.folderIdSelectDiv {
  width: 100%;
}

.noResults {
  padding: $fv-spacing-large $fv-spacing-small 0;
}

.moreResults {
  padding: $fv-spacing-large $fv-spacing-small 0;
  text-align: center;
}
