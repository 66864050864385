@import "node_modules/@fv-components/theme/src/_index.scss";

.loginContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: $fv-spacing-large;
  padding: 0 $fv-spacing-default;
  text-align: center;
}

.message,
.loginButton {
  margin: $fv-spacing-large 0;
}

.cancelContainer {
  display: flex;
  justify-content: flex-end;
  padding-right: $fv-spacing-large;
}