@import "node_modules/@fv-components/theme/src/_index.scss";

.createProjectForm {
  h3 {
    text-align: center;
  }
}

.projectTypesSelect {
  width: 100%;
  border-radius: $fv-spacing-small;
  padding: $fv-spacing-default;
  margin: $fv-spacing-default 0;
  border: $fv-border-default;
  
  &.projectTypesSelectMac {
    -webkit-appearance: initial;
  }
}

.orgClientWrapper {
  display: flex;
}

.orgClientWrapper > .searchOrgContactsGenCard {
  flex: 7;
  border: $fv-border-default;
  border-radius: $fv-border-radius-default;
  box-sizing: border-box;
  padding: $fv-spacing-default;
  margin: 0 0 $fv-spacing-default;
  min-height: 33px;
  color: $fv-color-grey-dark;
  font-size: $fv-font-size-smaller;
  justify-content: left;

  &:hover {
    background: white;
    cursor: text;

    &::before, &::after {
      background: white;
    }
  }
}

.createClientGenCard {
  flex: 1;
}

.orgName {
  display: block;
  width: 100%;
  border: $fv-border-default;
  border-radius: $fv-border-radius-default;
  box-sizing: border-box;
  padding: $fv-spacing-default;
  flex-grow: 1;
}

.projectName {
  display: block;
  width: 100%;
  border: $fv-border-default;
  border-radius: $fv-border-radius-default;
  box-sizing: border-box;
  padding: $fv-spacing-default;
  margin: 0 0 $fv-spacing-default;
  flex-grow: 1;
}

.projectSubmit {
  width: 100%;
  border: $fv-border-default;
  border-radius: $fv-border-radius-default;
}

.personChickletWrapper {
  flex: 7;
  padding: $fv-spacing-default;
  font-size: $fv-font-size-smaller;
  border: $fv-border-default;
  border-radius: $fv-border-radius-default;
  box-sizing: border-box;
  margin: 0 0 $fv-spacing-default;
  overflow: hidden;
}

.personChicklet {
  border: $fv-border-default;
  border-radius: $fv-border-radius-default;
  display: flex;
  padding: 0 $fv-spacing-small;
  white-space: nowrap;
  width: fit-content;
  max-width: 100%;
  > .personChickletLabel {
    flex: 7;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  > .projectClientRemove {
    font-weight: 100;
    padding: $fv-spacing-smaller;
    margin: 0 0 0 $fv-spacing-small;
    height: auto;
    line-height: 0;
    color: $fv-color-grey-dark;
    flex: 1;
  }
}

.personFakeAddWrapper {
  flex: 1;
  color: $fv-color-grey-light;
  text-align: center;
  padding: $fv-spacing-default;
  margin: 0 0 $fv-spacing-default;
  font-size: $fv-font-size-small;
  line-height: $fv-font-size-large;
}
