@import "node_modules/@fv-components/theme/src/_index.scss";

.teamPersona {
  flex: 1 10 auto;
  padding: $fv-spacing-small;
}

.teamPersonaContainer {
  display: flex;
  justify-content: space-between;
}

.teamAdvancedLevel {
  flex: 0 1 auto;
  justify-content: flex-end;
}

.teamAdvancedLevels {
  flex: 0 1 140px;
  justify-content: flex-end;
}

.teamAdvancedChip {
  font-size: $fv-font-size-smaller;
  padding: $fv-spacing-default;
  color: $fv-color-blue-default;
  background-color: $fv-color-blue-lighter;

  &:hover{
    cursor: default;
    color: $fv-color-blue-default;
    &:hover::before {
      opacity: 0;
    }
  }
}

.teamSeparator {
  margin: $fv-spacing-smaller $fv-spacing-small $fv-spacing-smaller $fv-spacing-small;
  border: none;
  border-top: $fv-border-default;
}
