@import "node_modules/@fv-components/theme/src/_index.scss";

@keyframes growWidth {
  0% {
    width: 32px;
  }
  100% {
    width: 100%;
  }
}

.searchHeader {
  background-color: white;
}

.searchContainer {
  align-items: center;
  justify-content: space-between;
  background-color: $fv-color-blue-default;
  display: flex;
  min-height: 50px;
  padding: $fv-spacing-smaller $fv-spacing-default;
}

.searchBar {
  border: none;
  border-radius: $fv-border-radius-default;
  box-sizing: border-box;
  padding: $fv-spacing-default;
  width: 100%;
  animation-name: growWidth;
  animation-duration: .5s;
}

.searchBarLogo {
  height: 18px;
  position: relative;
  top: 5px;
}

.searchBarResultsCount {
  padding:  $fv-spacing-small $fv-spacing-default $fv-spacing-default;
  font-weight: $fv-font-weight-bold;
  font-size: $fv-font-size-small;
}

.searchBarLogoContainer {
  min-height: 30px;
  min-width: 30px;
  background-color: white;
  border-radius: 50%;
  text-align: center;
  margin-right: $fv-spacing-default;
}

.searchTitle {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.searchBtn {
  cursor: pointer;
  background-color: white;
  border: none;
  padding: 0 $fv-spacing-default;
  border-radius: $fv-border-radius-default;
  margin-left: $fv-spacing-default;
}

.searchIcon {
  font-size: $fv-font-size-default;
  font-weight: $fv-font-weight-bolder;
  line-height: 2.0;
  padding: 0;
}

.projectName {
  color: white;
  font-size: $fv-font-size-default;

  &:hover {
    color: $fv-color-blue-light;
  }
}
