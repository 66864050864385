@import "node_modules/@fv-components/theme/src/_index.scss";

.emailActivityEmailProject {
  margin-top: $fv-spacing-default;
  margin-left: $fv-spacing-large;
}

.emailActivityEmailLinks {
  margin-top: $fv-spacing-large;
}

.emailActivityContainer {
  margin: 0 (-$fv-spacing-small);
}

.emailActivityInputContainer {
  padding: $fv-spacing-small;
  bottom: 0;
  background-color: white;
}

.emailActivitySendErrorText {
  margin: $fv-spacing-small 0 $fv-spacing-default;
}

.emailActivityDivider {
  border-top: $fv-spacing-smaller $fv-color-blue-default $fv-border-style-default;
}
