@import "node_modules/@fv-components/theme/src/_index.scss";

.vitalsContainer {
  font-size: $fv-font-size-smaller;
  padding: $fv-spacing-small 0 $fv-spacing-default;
  border-bottom: $fv-border-default;
}

.vitalRow {
  display: flex;
  padding-bottom: $fv-spacing-small;
}

.vitalName {
  width: 40%;
  text-align: right;
  font-style: italic;
  margin-right: $fv-spacing-default;
}

.vitalValueContainer {
  width: 57%;
  display: flex;
}

.vitalValue {
  font-weight: $fv-font-weight-bolder;
  align-self: flex-end;
  max-width: 100%;
}
