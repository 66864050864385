@import "node_modules/@fv-components/theme/src/_index.scss";

.card {
  position:fixed;
  top: 0;
  left: 0;
  z-index: 100;
  height: 100%;
  width: 100%;
  background: white;
  padding: $fv-spacing-large;
  animation-duration: .33s;
  animation-name: slidein;
}

@keyframes slidein {
  from {
    margin-left: 100%;
  }

  to {
    margin-left: 0%;
  }
}

.cardButton {
  width: 100%;
  margin-bottom: $fv-spacing-default;
}

.backButton {
  display: block;
  margin-bottom: $fv-spacing-large;
}
