@import "node_modules/@fv-components/theme/src/_index.scss";

.docItem {
  width: 100%;
  border-bottom: $fv-border-default;
  cursor: pointer;
  padding: $fv-spacing-large $fv-spacing-small;
  font-size: small;

  &:hover {
    background-color: $fv-color-grey-lightest;
  }
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  gap: $fv-spacing-default $fv-spacing-default;
  grid-template-areas:
    "top"
    "middle"
    "bottom"
    "footer";
}

.loadingOverlay {
  @extend .docItem;
  z-index: 999;
  background-color: white;
  opacity: .7;
}

.errorLoading { 
  color: $fv-color-red-bright;
  margin-right: $fv-spacing-small;
}
.top { 
  grid-area: top; 
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.middle {
  grid-area: middle;
}
.bottom {
  grid-area: bottom;
}
.footer {
  grid-area: footer;
}

.docIcon {
  margin-right: $fv-spacing-small;
}
.uploaderFullname {
  color: $fv-color-grey;
  font-style: italic;
}
.formatDate {
  float: right;
}
.hashTags {
  color: $fv-color-grey;
  font: $fv-font-size-small;
}
.pdf {
  color: #ff0000;
  opacity: .7; 
}
.eml {
  color: $fv-color-blue-bight;
}
.doc {
  color: $fv-color-blue-dark;
}
.excel {
  color: #217346;
}
.powerpoint {
  color: $fv-color-red-bright;
}
.image {
  color: $fv-color-teal;
}
.text {
  color: $fv-color-grey-darker;
  opacity: .6;
}
.video {
  color: $fv-color-red-dark;
}
.audio {
  color: $fv-color-red-dark;
}
.defaultFile {
  color: $fv-color-grey;
}