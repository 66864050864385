@import "node_modules/@fv-components/theme/src/_index.scss";

.teamHeader {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}

.teamTypes {
  flex-wrap: nowrap;
  flex-direction: column;
  padding: $fv-spacing-small 0;
}

@media only screen and (min-width: 300px) {
  .teamTypes {
    justify-content: space-around;
    flex-direction: row;
  }
}

.teamLevelChip {
  font-size: $fv-font-size-smaller;
  padding: $fv-spacing-small;
  border-radius: $fv-border-radius-default;
}

.teamSearchNoResults {
  margin-left: $fv-spacing-default;
}

.teamLoading {
  margin: $fv-spacing-default 0 0 $fv-spacing-small;
}
