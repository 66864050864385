@import "node_modules/@fv-components/theme/src/_index.scss";

.emailActivityChat {
  display: flex;
  flex-direction: column-reverse;
  align-content: flex-start;
  flex-wrap: wrap;
  min-height: 60vh;
  overflow: auto;
  background-color: $fv-color-grey-lightest;
  padding: $fv-spacing-default $fv-spacing-default 0;
  font-size: $fv-font-size-small;
}

.emailActivityComment {
  width: 100%;
  margin-bottom: $fv-spacing-small;
}

.emailActivityCommentBody {
  border-radius: $fv-border-radius-default;
  background-color: white;
  padding: $fv-spacing-default;
  font-size: $fv-font-size-small;
}

.emailActivityCommentPosted {
  font-size: $fv-font-size-smaller;
  color: $fv-color-grey;
  padding: $fv-spacing-small 0 $fv-spacing-small $fv-spacing-default;
  display: flex;
  flex-wrap: wrap;
}

.emailActivityCommentAuthor {
  margin-right: $fv-spacing-smaller;
}