@import "node_modules/@fv-components/theme/src/_index.scss";

.projectWorkspaceHeader {
  flex: 0 1 auto;
  margin-bottom: $fv-spacing-small;
  position: relative;
}

.projectWorkspaceContent {
  flex: 1 1 auto;
  overflow: auto;
  padding: 0 $fv-spacing-default;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

.projectWorkspaceContent::-webkit-scrollbar { /* WebKit */
  width: 0;
  height: 0;
}
