@import "node_modules/@fv-components/theme/src/_index.scss";

.folderLinks {
  margin-left: $fv-spacing-small;
  margin-top: $fv-spacing-default;
}

.folderLink {
  color: $fv-color-blue-default;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.folderDir {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 33%;
  font-size: $fv-font-size-small;
}

.folderDirRoot {
  max-width: 90%;
}

.folderIcon {
  margin-right: $fv-spacing-smaller;
  margin-left: $fv-spacing-smaller;
  font-size: $fv-font-size-smaller;
}