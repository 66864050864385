@import "node_modules/@fv-components/theme/src/_index.scss";


.container {
  padding-bottom: $fv-spacing-large;

  .counter {
    margin-right: $fv-spacing-large;
    text-align: right;
  }

  & .input {
    width: 100%;
    margin-top: $fv-spacing-large;
  }
  
  & .select {
    width: 100%;
    margin: $fv-spacing-large 0 18px 0;

    & select {
      padding-right: 35px;
      padding-left: $fv-spacing-default;
    }
  }
}

.success {
  margin: $fv-spacing-default auto;
  width: fit-content;
}

.noCodes {
  margin: $fv-spacing-default;
  font-size: $fv-font-size-small;
}

.saveAsDraft {
  float: right;
  color: $fv-color-grey-dark;
  padding-top: $fv-spacing-large;
  font-size: $fv-font-size-small;
}