@import "node_modules/@fv-components/theme/src/global.scss";
@import "node_modules/@fv-components/theme/src/_index.scss";

// Global overrides
html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-wrap: break-word;
  font-family: $fv-font-family-default;
  color: $fv-color-grey-dark;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

body::-webkit-scrollbar { /* WebKit */
  width: 0;
  height: 0;
}

div, textarea {
  box-sizing: border-box;
}

textarea {
  display: block;
  margin-bottom: $fv-spacing-smaller;
}

a {
  text-decoration: none;
  color: $fv-color-blue-dark;
}

:focus {
  outline: none;
}

// Global MCWR overrides
.mdc-button {
  text-transform: capitalize;
  height: 30px;
  min-width: initial;
}

.mdc-list-item {
  height: initial;
  min-height: 48px;
}

// Specific MCWR overrides
.mdc-button.email-actions {
  color: $fv-color-blue-default;
  padding: $fv-spacing-small;
  margin-right: $fv-spacing-default;
  background-color: white;
  height: 25px;
  font-size: $fv-font-size-smaller;
}

.mdc-button.contact-more-btn {
  padding: 0;
  margin-right: $fv-spacing-small;
}

.project-workspace-tab-container .mdc-tab-scroller__scroll-area--scroll {
  overflow-x: hidden;
}