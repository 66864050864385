@import "node_modules/@fv-components/theme/src/_index.scss";

.searchResultsProjectCard {
  cursor: pointer;
  font-size: $fv-font-size-smaller;
  padding: $fv-spacing-default;
  border-bottom: $fv-border-grey-lighter;

  &:hover {
    background-color: $fv-color-blue-lighter;
    box-shadow: inset 0px 0px 4px 1px rgba(0,0,0,0.2);
  }
}

.searchResultsProjectTitle {
  color: $fv-color-orange-dark;
  font-size: $fv-font-size-default;
  font-weight: $fv-font-weight-bold;
  padding-bottom: $fv-spacing-default;
}

.searchResultsError {
  margin: $fv-spacing-default;
}
