@import "node_modules/@fv-components/theme/src/_index.scss";

.container {
  height: 100vh;
  background-color: #F3F3ED;

  a {
    display: block;
    margin-bottom: $fv-spacing-large;
    font-size: $fv-font-size-small;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }

  // Amplify UI overrides
  input {
    &:focus {
      border: $fv-border-blue !important;
      box-shadow: 0 0 4px $fv-color-blue-default;
    }
  }

  button[type='submit'] {
    &:hover {
      box-shadow: inset 0 8px 2px #004057;
    }

    &:active {
      background-color: #00394d !important;
    }
  }

  button[data-test='sign-out-button'] {
    visibility: hidden;
  }

  button[data-test='sign-in-sign-in-button'] {
    &:disabled {
      font-size: 0px !important;
      background-color: $fv-color-grey-darker !important;

      &:hover {
        box-shadow: none;
      }

      &::after {
        font-size: 18px !important;
        content: 'Signing In...'
      }
    }
  }
}

.content {
  max-width: 340px;
  margin: 0 auto;

  .IDPBtn {
    width: 100%;
    margin-top: $fv-spacing-large;
    text-transform: initial;
    color: $fv-color-grey-darker;
    border: $fv-border-grey-lighter;
    background-color: white;

    &:hover {
      border-color: $fv-color-grey-darker;
    }
  }
}

.logo {
  margin: 60px auto 40px;
  width: 100%;
}

.forgotLink {
  font-size: $fv-font-size-small;
  &:hover {
    text-decoration: underline;
  }
}

.shardOptions {
  text-align: center;
}

.shardBtn {
  display: block;
  margin: $fv-spacing-large auto;
  width: 90%;
  min-height: 40px;

  span {
    display: flex;
    justify-content: space-between;
    line-height: $fv-font-size-smaller;
    text-transform: initial;
  }
}

.clearShardBtn {
  display: block;
  margin: 0 auto $fv-spacing-large;
  min-height: 40px;

  span {
    text-transform: initial;
  }
}

.clearShardBtnIcon {
  margin-right: $fv-spacing-default;
}

.customOption {
  display: flex;
  justify-content: space-between;
  margin: $fv-spacing-large auto;
  width: 95%;
  min-height: 40px;
}

.customOptionTitle {
  margin-top: 40px;
}

.customInputTeam {
  width: 130px;
  border-top-left-radius: $fv-border-radius-default;
  border-bottom-left-radius: $fv-border-radius-default;
  border-width: 1px;
  border-color: $fv-color-grey-light;
  border-right: none;
  padding-right: $fv-spacing-small;
  padding-left: $fv-spacing-default;
  min-height: 36px;
}

.customInputDomain {
  width: 130px;
  border-top-right-radius: $fv-border-radius-default;
  border-bottom-right-radius: $fv-border-radius-default;
  border-width: 1px;
  border-color: $fv-color-grey-light;
  padding-left: $fv-spacing-small;
  padding-right: $fv-spacing-small;
  min-height: 40px;
  background-color: rgba(0, 0, 0, 0.12);
}

.completeRounded {
  border-radius: $fv-border-radius-default;
}

.customBtn {
  min-height: 40px;
}

.customNotFound {
  margin: $fv-spacing-large auto;

  a {
    display: inline;
  }
}

.locationOptionContainer {
  justify-content: space-between;
  display: flex;
  width: 80%;
  margin: auto;
  margin-top: 20px;
}

.locationOption {
  width: 110px;
  border: 2px solid #E0E0E0;
  padding: 15px, 20px, 15px, 20px;
  height: 65px;
  text-align: center;
  font-weight: 400;
  cursor: pointer;
  user-select: none;
}

.active {
  border-color: #08BDBA;
}

.locationCountry {
  font-size: 13px;
  line-height: 20px;
  margin: 13px 0 0 0;
}

.locationDomain {
  font-size: 11px;
  line-height: 15px;
  margin: 0 0 10px 0;
}

.fvidLoginBox {
  width: 300px;
  height: 290px;
  gap: 20px;
  border-radius: 10px;
  text-align: center;
  background-color: #ffffff;
  padding-top: 30px;
  margin: auto;
}

.fvidLoginBody {
  font-size: 15px;
}

.fvidButtonIconRight {
  margin-right: $fv-spacing-default;
}

.fvidButtonIconLeft {
  margin-left: $fv-spacing-default;
}