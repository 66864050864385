@import "node_modules/@fv-components/theme/src/_index.scss";

.taskpaneContainer {
  display: flex;
  flex-flow: column;

  &.viewportHeight {
    height: 100vh;
  }
}

.taskpaneHeader {
  position: sticky;
  top: 0;
  background-color: white;
}

.signOutBtn {
  margin: $fv-spacing-large auto;
}

.signOutMSALBtn {
  margin: 40px auto;
}

.createProjectCard {
  justify-content: left;
  border-bottom: $fv-border-grey-lighter;
  border-radius: unset;
}

.searchBarResultsCount {
  padding:  $fv-spacing-small $fv-spacing-default $fv-spacing-default;
  font-weight: $fv-font-weight-bold;
  font-size: $fv-font-size-small;
  border-bottom: $fv-border-grey-lighter;
}

.notDocsPlus {
  padding: 30px;
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-weight: $fv-font-weight-bolder;
}
