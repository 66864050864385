@import "node_modules/@fv-components/theme/src/_index.scss";

.container {
  height: 54px;
  display: grid;
  grid-template-columns: 2fr 7fr 1fr;
  margin: $fv-spacing-smaller;
}

.persona {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.text {
  display: grid;
  grid-template-rows: 33.33%;
  align-items: center;
  vertical-align: middle;
}

.fullname {
  font-size: $fv-font-size-small;
  font-weight: $fv-font-weight-bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.email {
  font-size: $fv-font-size-smaller;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.phone {
  font-size: $fv-font-size-smaller;
}

.cardButton {
  display: flex;
  justify-content: center;
  align-items: center;
}