@import "node_modules/@fv-components/theme/src/_index.scss";

.createContactsForm {
  text-align: center;
}

.createContactsForm button[type="submit"] {
  width: 100%;
  border: $fv-border-default;
  border-radius: $fv-border-radius-default;
}

input.createContact {
  display: block;
  width: 100%;
  border: $fv-border-default;
  border-radius: $fv-border-radius-default;
  box-sizing: border-box;
  padding: $fv-spacing-default;
  margin: 0 0 $fv-spacing-default;
  flex-grow: 1;
}

input.alert {
  border-color: $fv-color-red-dark;
  background-color: rgba($fv-color-red-bright, .15);
}

.contactHelperText input.createContact {
  margin-bottom: 0;
}

.contactHelperText small {
  display: block;
  text-align: left;
  font-size: $fv-font-size-smaller;
  margin-bottom: $fv-spacing-default * 2;
}

.createContactErrorText {
  text-align: left;
  margin-top: $fv-spacing-default;
}
