@import "node_modules/@fv-components/theme/src/_index.scss";

.errorMsgContainer {
  align-items: left;
}

.errorMsgSupport {
  margin-top: $fv-spacing-large;
}

.firstLine {
  margin-bottom: $fv-spacing-large;
}