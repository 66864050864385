@import "node_modules/@fv-components/theme/src/_index.scss";

.component {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  width: 100%;
  background: $fv-color-grey-lightest;
  padding: $fv-spacing-small;
  margin: auto;
  align-items: center;
  padding-bottom: $fv-spacing-small;
  font-size: $fv-font-size-small;
}
.filenameContainer {
  align-items: inherit;
  display: grid;
  grid-template-columns: 25px auto 25px;
}

.filenameContainerIndent {
  @extend .filenameContainer;
  padding-left: $fv-spacing-large;
}
.folderDestinationContainer {
  align-items: inherit;
  margin-bottom: 0px;
  height: 20px;
  display: grid;
  grid-template-columns: 25px auto;
  white-space: nowrap;
  overflow: hidden;
}

.folderDestinationContainerUnselected {
  @extend .folderDestinationContainer;
  color: $fv-color-grey-light;
  font: italic;
}

.checkIcon,
.editIcon,
.saveIcon,
.cancelIcon {
  color: $fv-color-blue;
  padding: $fv-spacing-small;
  cursor: pointer;
}

.checkButton,
.editButton,
.saveButton,
.cancelButton {
  background: inherit;
  padding: 0;
  border: 0;
}

.editFilenameView {
  display: grid;
  grid-template-columns: auto 25px 0px;
}

.checkbox {
  color: $fv-color-blue;
}

.filenameDisplay {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.filenameDisplayUnselected {
  @extend .filenameDisplay;
  color: $fv-color-grey-light;
  font: italic;
}

.input,
.inputHasErrors {
  border: $fv-border-default;
  border-radius: $fv-border-radius-default;
}

.inputHasErrors {
  border-color: $fv-color-red-bright;
}

.errorMessage {
  display: grid;
  font-size: x-small;
  color: $fv-color-red-bright;
  grid-template-columns: auto;
  grid-column-start: 2;
}

.folderIcon {
  padding: $fv-spacing-small;
}

.emlIcon {
  padding-right: $fv-spacing-smaller;
}