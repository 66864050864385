@import "node_modules/@fv-components/theme/src/_index.scss";

.projectWorkspaceTabBar {
  position: relative;
  background-color: white;
}

.projectWorkspaceTab {
  height: initial;
  padding: 0 $fv-spacing-small;
}

.projectWorkspaceTabIcon {
  padding: $fv-spacing-small 0 0;
  font-size: $fv-font-size-large;
}

.projectWorkspaceTabLabel {
  text-transform: initial;
  font-size: $fv-font-size-small;
  padding-bottom: $fv-spacing-small;
}