@import "node_modules/@fv-components/theme/src/_index.scss";

.secondarySearchBarContainer {
  display: flex;
}

.secondarySearchBarLoading {
  width: 99.5%;
  margin: -$fv-spacing-small auto $fv-spacing-smaller;
  border-radius: 0 0 5px 5px;
}

.secondarySearchBarInput {
  border: $fv-border-default;
  border-radius: $fv-border-radius-default;
  box-sizing: border-box;
  padding: $fv-spacing-small;
  margin: 0;
  flex-grow: 1;
  height: 25px;
}
