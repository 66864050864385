@import "node_modules/@fv-components/theme/src/_index.scss";

.progressWrapper {
  text-align: center;
  width: 80%;
  margin: 0 auto;

  img {
    width: initial;
  }
}