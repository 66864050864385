@import "node_modules/@fv-components/theme/src/_index.scss";

.folderPickerWrapper {
  --back-button-height: 40px;

  height: 100%;
  max-height: calc(100% - var(--back-button-height));
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.container {
  flex: 1;
  overflow: auto;
  font-size: $fv-font-size-small;
  padding-bottom: $fv-spacing-large;
  scrollbar-width: thin;
}

.folderIcon {
  margin-right: $fv-spacing-smaller;
  margin-left: $fv-spacing-smaller;
  font-size: $fv-font-size-smaller;
}

.folderContainer {
  width: 100%;
  border-bottom: $fv-border-default;
}

.folderItem {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  padding: $fv-spacing-large $fv-spacing-small $fv-spacing-large $fv-spacing-large;
  &:hover {
    background-color: $fv-color-grey-lightest;
  }
}

.folderBreadcrumbSection {
  display: grid;
  grid-template-columns: 9fr 1fr;
  border-radius: $fv-spacing-smaller;
  padding: $fv-spacing-default 0 $fv-spacing-default $fv-spacing-smaller;
}

.folderBreadcrumbSectionHighlighted {
  @extend .folderBreadcrumbSection;
  background-color: $fv-color-blue-lighter;
}

.folderPlaceholder {
  padding: $fv-spacing-large $fv-spacing-small $fv-spacing-large $fv-spacing-small;
  width: calc(100% - 6 * $fv-spacing-large);
}

.sortButton {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.docPlaceholder {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: $fv-spacing-small;
}

.icon {
  padding: $fv-spacing-small;
  color: black;
}

.docContainer {
  width:100%;
  border-bottom: $fv-border-default;
  position: relative;
  min-height: calc(3 * $fv-spacing-large);
  overflow: hidden;
}

.folderLinks {
  margin-left: $fv-spacing-small;
  margin-top: $fv-spacing-default;
}

.folderLink {
  color: $fv-color-blue-default;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.folderIcon {
  margin-right: $fv-spacing-smaller;
  margin-left: $fv-spacing-smaller;
  font-size: $fv-font-size-smaller;
}

.projectNameHighlighted:hover, .parentNameHighlighted:hover {
  text-decoration: underline;
  cursor: pointer;
}

.breadCrumbs {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
