@import "node_modules/@fv-components/theme/src/_index.scss";

.component {
  display: flex;
  width: 100%;
  padding: $fv-spacing-small;
  margin: auto;
  align-items: center;
  padding-bottom: $fv-spacing-small;
  font-size: $fv-font-size-small;
}

.label {
  font-weight: bold;
}

.filenameControl {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.filenameDisplay {
  display: flex;

  input.input,
  input.inputHasErrors {
    flex: 1;
    border: $fv-border-default;
    border-radius: $fv-border-radius-default;
    padding: $fv-spacing-small;
    border-right: none;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    max-height: 28px;
  }

  .extension {
    flex: 0 0 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: $fv-border-default;
    border-radius: $fv-border-radius-default;
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 0 $fv-spacing-small;
    font-size: $fv-font-size-small;
    max-height: 28px;
  }

  input.inputHasErrors,
  .extension.inputHasErrors {
    border-color: $fv-color-red-bright;
  }
}


.errorMessage {
  display: grid;
  font-size: x-small;
  color: $fv-color-red-bright;
  grid-template-columns: auto;
  grid-column-start: 2;
}
