@import "node_modules/@fv-components/theme/src/_index.scss";

.contactsHeader {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  background-color: white;
  z-index: 1;
  padding-bottom: $fv-spacing-small;
}

.contactsSeparator {
  margin: $fv-spacing-smaller $fv-spacing-small;
  color: $fv-color-grey-light;
  border: none;
  border-top: $fv-border-default;
}

.contactsNoResults, .contactsLoading {
  margin: $fv-spacing-default;
}

.projectEmail {
  font-size: $fv-font-size-smaller;
  margin: 0 $fv-spacing-small $fv-spacing-default;
}

.contactsSplit {
  display: flex;
}

.contactsSearch {
  flex: 7;
}

.contactsCreateWrapper {
  flex: 1;
}

.contactsCreateWrapper .createProjectContactGenCard {
  height: 25px;
}
