@import "node_modules/@fv-components/theme/src/_index.scss";

.loginContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: $fv-spacing-large;
  padding: 0 $fv-spacing-default;
  text-align: center;
}

.loginCTA,
.loginButton {
  margin-bottom: $fv-spacing-large;
}

.helpText {
  margin: $fv-spacing-large 0 $fv-spacing-smaller 0;
}
