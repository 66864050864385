@import "node_modules/@fv-components/theme/src/_index.scss";

.folderPickerContainer {
  margin-top: $fv-spacing-large;
  padding: $fv-spacing-small;
  display: flex;
  align-items: baseline;
  gap: $fv-spacing-small;
  border-top: 1px solid $fv-color-blue-default;

  .destinationLabel {
    font-weight: bold;
    font-size: $fv-font-size-small;
  }

  [data-test="genericCardOpen"] {
    flex-basis: 30px;
    margin-bottom: 0;
  }
}

.save {
  display: flex;
  justify-content: center;
}

.saveCentered {
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.saveButton {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.saveProgress {
  width: 100%;
}

.errorMessage {
  margin-top: $fv-spacing-large;
  text-align: center;
  font-size: small;
  color: $fv-color-red-bright;
}

.success {
  margin-top: $fv-spacing-large;
  text-align: center;
  font-size: small;
  color: green;
}
