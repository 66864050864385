@import "node_modules/@fv-components/theme/src/_index.scss";

.destinationFolder {
  margin: $fv-spacing-small;
  margin-top: 0;
  padding: $fv-spacing-small;
  padding-top: $fv-spacing-large;
  padding-bottom: $fv-spacing-large;
  border-bottom: 1px solid $fv-color-blue-default;

  .icon {
    margin-left: $fv-spacing-small;
    margin-top: $fv-spacing-default;
  }
  
  .folderLevel {
    margin-left: $fv-spacing-small;
    margin-top: $fv-spacing-default;
  }

  .folderIcon {
    margin-right: $fv-spacing-smaller;
    margin-left: $fv-spacing-smaller;
    font-size: $fv-font-size-smaller;
  }

  .breadCrumbs {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
